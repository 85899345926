<template>
    <div>
        <b-card class="contacts-cardtop">
            <div class="contacts-cardtoptextdiv">
                <h5 class="contacts-cardtitle">Todos os Contatos</h5>
                <div class="contacts-total">
                    <span class="me-2">Total de Contatos</span>
                    <span class="contacts-totalnumber">{{ totalContacts || 0 }}</span>
                </div>
            </div>
            <div class="contacts-cardtopbtndiv">
                <!-- <b-button class="contacts-cardtopbtn contacts-cardtopbtnfilter shadow-none" v-b-modal.modal-profileclient>Criar</b-button> -->
                <b-button class="contacts-cardtopbtn contacts-cardtopbtnremove shadow-none" @click="disableFilter()" v-if="filterEnabled">Remover Filtro</b-button>
                <b-button class="contacts-cardtopbtn contacts-cardtopbtnfilter shadow-none" v-b-modal.modal-filter>Filtrar</b-button>
                <!-- <b-button class="contacts-cardtopbtn contacts-cardtopbtnremove shadow-none" @click="deleteContacts">Remover</b-button> -->
                <b-button class="contacts-cardtopbtn contacts-cardtopbtnexport shadow-none" @click="exportCSV">Exportar</b-button>
                <!-- <b-button class="contacts-cardtopbtn shadow-none" @click="closeDelete" v-if="remove">Cancelar</b-button> -->
            </div>
        </b-card>
        <div v-if="contacts.length">
            <b-row class="m-0 mt-3" cols="3">
                <b-col class="contactcardcol rounded" v-for="item in contacts" :key="item.id">
                    <div class="card-deck h-100 bg-white rounded">
                        <b-card class="contactcard">
                            <b-row class="m-0 text-white">
                                <div class="p-0 py-4 bg-purple rounded-top d-flex flex-row justify-content-center position-relative" :class="`${item.blocked && 'bg-warning'}`">
                                    <div class="d-flex justify-content-between px-4 position-absolute w-100 z-1">                 
                                        <span class="threedots bg-white" :class="`${ item.blocked ? 'text-warning' : 'text-purple' }`">
                                            <span class="d-flex align-items-center justify-content-center h-100">
                                                <b-icon class="threedotsicon" icon="three-dots"></b-icon>
                                            </span>
                                            <div class="threedotsdrop">
                                                <div class="threedotsdroptriangle"></div>
                                                <div class="threedotsdropcontent">
                                                    <div class="threedotsdropbtns" @click.prevent="selectContact(item); getOperators(); getAllTags()" v-b-modal.modal-profileclient>Perfil</div>
                                                    <div class="threedotsdropbtns" @click.prevent="selectContact(item); loadProtocols(item)" v-b-modal.attendanceHistory>Histórico de Atendimentos</div>
                                                    <!-- <div class="threedotsdropbtns" @click.prevent="selectContact(item)" v-b-modal.modal-removecontact>Remover Contato</div> -->
                                                </div>
                                            </div>
                                        </span>
                                        <span class="hstack gap-2">
                                            <!-- <span class="taghover" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-clientaccess' }" :title="`${item.access} Gobot`" v-if="item.access=='Cliente'">
                                                <b-icon class="taghovericon" icon="tag"  style="background-color:hsl(107deg 62% 78%)"></b-icon>
                                            </span>
                                            <span class="taghover" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-clientoperator' }" :title="`${item.access} Gobot`" v-else>
                                                <b-icon class="taghovericon" icon="tag" style="background-color:hsl(52deg 100% 50%)"></b-icon>
                                            </span> -->
                                            <!-- <span class="bg-danger rounded-circle px-1">
                                                <b-icon icon="x"></b-icon>
                                            </span> -->
                                            <span class="tagSpan position-relative" role="button" v-if="item.tag && getTagInfo(item.tag)">
                                                <b-icon class="rounded-circle p-2-5 darkenOnHover" :style="`background-color: ${getTagInfo(item.tag)?.color}`" font-scale="3.5" icon="tag"></b-icon>
                                                <div class="tagSpanHover position-absolute mt-2 py-1 px-2 rounded pe-auto" :style="`background-color: ${getTagInfo(item.tag)?.color}`">
                                                    {{ getTagInfo(item.tag)?.name }}
                                                </div>
                                            </span>
                                            <span v-b-tooltip.hover.bottom="{ variant: 'primary', customClass: 'top-0 mt-1 tooltip-text-white', boundary: 'document' }" title="Encaminhar Atendimento" role="button" @click.prevent="selectContact(item); getForwardList()" v-b-modal.modalForward>
                                                <b-iconstack class="rounded-circle p-2 darkenOnHover bg-primary" font-scale="3.5">
                                                    <b-icon shift-h="-1" stacked icon="box-arrow-in-right" scale="1"></b-icon>
                                                </b-iconstack>
                                            </span>
                                            <span v-b-tooltip.hover.bottom="{ variant: (item.blocked ? 'purple' : 'warning'), customClass: 'top-0 mt-1 tooltip-text-white', boundary: 'document' }" :title="item.blocked ? 'Desbloquear Contato' : 'Bloquear Contato'" role="button" @click.prevent="selectContact(item)" v-b-modal.modal-blockcontact>
                                                <b-iconstack class="rounded-circle p-2 darkenOnHover" :class="`${ item.blocked ? 'bg-purple' : 'bg-warning' }`" font-scale="3.5">
                                                    <b-icon stacked icon="slash" scale="1.5"></b-icon>
                                                    <b-icon stacked icon="circle"></b-icon>
                                                </b-iconstack>
                                            </span>
                                            <span v-b-tooltip.hover.bottom="{ variant: 'danger', customClass: 'top-0 mt-1', boundary: 'document' }" title="Remover Contato" role="button" @click.prevent="selectContact(item)" v-b-modal.modal-removecontact>
                                                <b-icon class="bg-danger rounded-circle p-2-5 darkenOnHover" font-scale="3.5" icon="trash"></b-icon>
                                            </span>
                                        </span>
                                    </div>
                                    <!-- <span class="taghover" v-else>
                                        <b-form-checkbox class="checkboxdelete d-flex align-items-center justify-content-center" button button-variant="light"
                                        :id="`checkbox${item.order}`"
                                        v-model="remove"
                                        :name="`checkbox${item.order}`"
                                        :value="item"
                                        >
                                            <b-icon icon="x"></b-icon>
                                        </b-form-checkbox>
                                    </span> -->
                                    <div class="text-center mt-4">
                                        <div class="position-relative">
                                            <b-avatar class="contacticon bg-secondary" :src="item.photoURL"></b-avatar>
                                            <!-- <span class="position-absolute" style="right: 0em; top: .5em;">
                                                <div class="rounded-pill px-2 py-1 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(item.tag)?.color}; font-size: 0.6em`" role="button" @click="toggleTagName(item)" v-if="item.tag && getTagInfo(item.tag)">
                                                    <b-icon icon="circle-fill"></b-icon>
                                                    &#8205;
                                                    <div class="ms-1 text-uppercase" v-show="item.showTagName">
                                                        {{ getTagInfo(item.tag)?.name }}
                                                    </div>
                                                </div>
                                            </span> -->
                                        </div>
                                        <div class="contactname">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                            <b-row class="m-0" :class="`${ item.blocked ? 'text-warning' : 'text-purple' }`">
                                <b-col class="p-0">
                                    <div class="border-bottom p-3 d-flex align-items-center">
                                        <b-icon class="fs-3 me-2" icon="phone"></b-icon>
                                        <span>
                                            {{ item.number || 'N/D' }}
                                        </span>
                                    </div>
                                    <div class="border-bottom p-3 d-flex align-items-center">
                                        <b-icon class="fs-3 me-2" icon="geo-alt"></b-icon>
                                        <span>
                                            {{ user.channel?.ddi && user.channel.ddi != "55" ? parseCountry(item.country) : parseState(item.state) }}
                                        </span>
                                    </div>
                                    <div class="border-bottom p-3 d-flex align-items-center">
                                        <b-icon class="fs-3 me-2" icon="at"></b-icon>
                                        <span>
                                            {{ item.email || 'N/D' }}
                                        </span>
                                    </div>
                                    <div class="border-bottom p-3 d-flex align-items-center">
                                        <b-icon class="fs-3 me-2" icon="person-badge"></b-icon>
                                        <span>
                                            {{ parseGender(item.genre) || 'N/D' }}
                                        </span>
                                    </div>
                                    <div class="p-3 d-flex align-items-center" v-if="user.channelConfig?.enableCustomerPortfolio">
                                        <b-icon class="fs-3 me-2" icon="person"></b-icon>
                                        <span>
                                            {{ getOperatorName(item.responsibleOperatorId) || "Não Vinculado" }}
                                        </span>
                                    </div>
                                    <!-- <div class="p-3 d-flex align-items-center">
                                        <b-icon class="fs-3 me-2" icon="tag"></b-icon>
                                        <span class="rounded-pill px-3 py-1 text-white" :style="`background-color: ${getTagInfo(item.tag).color}`" v-if="getTagInfo(item.tag)">
                                            {{ getTagInfo(item.tag).name || 'N/D' }}
                                        </span>
                                        <span v-else>
                                            - - - -
                                        </span>
                                    </div> -->
                                </b-col>
                            </b-row>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
            <div class="contactspagination d-flex justify-content-between align-items-center" v-if="totalContacts>9">
                <b-pagination class="m-0"
                :total-rows="totalContacts"
                v-model="currentPage"
                :per-page="perPage"
                @change="pageChange"
                ></b-pagination>
                <span class="pagdescription">
                    <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{(currentPage * perPage > totalContacts) ? totalContacts : currentPage * perPage}} de {{totalContacts}} contatos cadastrados</div>
                </span>
            </div>
        </div>
        <div class="border-top border-grey rounded-bottom bg-white d-flex justify-content-center p-3" v-else>
            <div class="text-secondary">Nenhum contato encontrado!</div>
        </div>
        <b-modal id="modal-filter" ref="modal-filter" header-class="py-0 px-3" body-class="p-0" title="Filtrar Contatos">
            <form class="p-3" @submit.prevent="getContacts()">
                <b-form-group label="Selecione o Filtro:" label-class="text-purple fw-semibold">
                    <b-form-select class="w-100 rounded p-2 border-grey" v-model="filter.type">
                        <b-form-select-option :value="null">Todos</b-form-select-option>
                        <b-form-select-option value="keyword">Palavra-Chave</b-form-select-option>
                        <b-form-select-option value="region">Região</b-form-select-option>
                        <b-form-select-option value="gender">Gênero</b-form-select-option>
                        <b-form-select-option value="period">Período</b-form-select-option>
                        <b-form-select-option value="attendances">Atendimentos</b-form-select-option>
                        <b-form-select-option value="customerPortfolio">Carteira de Clientes</b-form-select-option>
                        <b-form-select-option value="blocked">Bloqueados</b-form-select-option>
                    </b-form-select>
                    <b-form-input class="mt-3 border-grey" placeholder="Buscar por nome ou número" v-model="filter.search" v-if="filter.type == 'keyword'"></b-form-input>
                    <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="filter.search" :options="selectStateOptions" v-else-if="filter.type == 'region'"></b-form-select>
                    <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="filter.search" v-else-if="filter.type == 'gender'">
                    <b-form-select-option value="M">Masculino</b-form-select-option>
                        <b-form-select-option value="F">Feminino</b-form-select-option>
                        <b-form-select-option :value="null || undefined">Não identificado</b-form-select-option>
                    </b-form-select>
                    <div class="w-100 mt-2" v-else-if="filter.type == 'period' || filter.type == 'attendances'">
                        <div class="w-50 pe-1 d-inline-block">
                            <b-form-group label="De:">
                                <b-form-input class="border-grey" type="date" v-model="filter.startDate"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="w-50 ps-1 d-inline-block">
                            <b-form-group label="Até:">
                                <b-form-input class="border-grey" type="date" v-model="filter.endDate"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="filter.search" v-else-if="filter.type == 'customerPortfolio'">
                        <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <!-- <div>
                    <b-form-input v-model="filter.text" placeholder="Filtrar por nome ou número"></b-form-input>
                </div> -->
                <div>
                    <b-button type="submit" class="modal-btn modal-btnfilter shadow-none">Filtrar</b-button>
                    <b-button @click="$bvModal.hide('modal-filter')" class="modal-btn shadow-none">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-profileclient" ref="modal-profileclient" dialog-class="modalclientdialog" modal-class="pt-4" content-class="border-0" header-class="registerclientheader profileclientheader py-5 d-flex flex-column justify-content-center" body-class="p-4" hide-footer v-if="objAux">
            <template #modal-header>
                <div class="position-relative">
                    <b-avatar class="contacticon clientavatarprofile bg-secondary text-white" :src="objAux.photoURL"></b-avatar>
                    <!-- <span class="position-absolute">
                        <b-icon font-scale="2" variant="light" icon="pencil-square" role="button"></b-icon>
                    </span> -->
                    <span class="position-absolute" style="right: -1em; top: -2em;">
                        <div class="rounded-pill px-2 py-1 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(objAux.tag)?.color}; font-size: 0.5em`" role="button" @click="toggleTagName(objAux)" v-if="objAux.tag">
                            <b-icon icon="circle-fill"></b-icon>
                            &#8205;
                            <div class="ms-1 text-uppercase" v-show="objAux.showTagName">
                                {{ getTagInfo(objAux.tag)?.name }}
                            </div>
                        </div>
                    </span>
                </div>
                <b-form-input class="registerclientnameinput w-50 mt-2" placeholder="Nome do Contato" v-model="objAux.name" required></b-form-input>
                <b-icon @click="$bvModal.hide('modal-profileclient')" class="modalclosebtn" font-scale="3" variant="light" icon="x" role="button"></b-icon>
            </template>
            <form @submit.prevent="saveContact">
                <div class="formwrapper formwrapperprofile">
                    <div class="formtitle">Dados do Contato</div>
                    <div>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="WhatsApp">
                                    <b-form-input class="modalclientinput" placeholder="55dd88888888" v-model="objAux.number" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Código do Cliente">
                                    <b-form-input class="modalclientinput" v-model="objAux.clientId"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="Email">
                                    <b-form-input type="email" class="modalclientinput" v-model="objAux.email"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="CPF/CNPJ">
                                    <b-form-input class="modalclientinput" v-model="objAux.document" v-maska='["###.###.###-##", "##.###.###/####-##"]'></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="Gênero">
                                    <b-form-select class="modalclientselect text-dark" v-model="objAux.genre">
                                        <b-form-select-option value="M">Masculino</b-form-select-option>
                                        <b-form-select-option value="F">Feminino</b-form-select-option>
                                        <b-form-select-option :value="null || undefined">Não identificado</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="País" v-if="user.channel?.ddi && user.channel.ddi != '55'">
                                    <b-form-select class="modalclientselect text-dark" v-model="objAux.country" :options="selectCountryOptions"></b-form-select>
                                </b-form-group>
                                <b-form-group label="Estado">
                                    <b-form-select class="modalclientselect text-dark" v-model="objAux.state" :options="selectStateOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="Data de Nascimento">
                                    <b-form-input class="modalclientinput" type="date" v-model="objAux.birthDate"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col v-if="tags.length">
                                <b-form-group label="Etiqueta">
                                    <b-form-select class="modalclientselect text-dark" v-model="objAux.tag" :options="selectTagOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="pt-3" v-if="user.channelConfig?.enableCustomerPortfolio">
                        <b-form-group label="Operador vinculado:">
                            <b-form-select class="modalclientselect text-dark" v-model="objAux.responsibleOperatorId">
                                <option value="null">Nenhum</option>
                                <option v-for="item in operators" :key="item.id" :value="item._id">{{ item.name }}</option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <!-- <div class="formtitle mt-4">Endereço</div>
                    <div>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="CEP">
                                    <b-input-group class="mt-3">
                                        <template #append>
                                        <b-input-group-text class="modalclientappend" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-searchcep' }" title="Buscar CEP">
                                            <b-icon icon="search" variant="light" rotate="90"></b-icon>
                                        </b-input-group-text>
                                        </template>
                                        <b-form-input class="modalclientinput w-0" v-model="objAux.cep"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Rua/Avenida">
                                    <b-form-input class="modalclientinput" v-model="objAux.street"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="Bairro">
                                    <b-form-input class="modalclientinput" v-model="objAux.district"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Número">
                                    <b-form-input class="modalclientinput" v-model="objAux.streetnumber"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="Complemento">
                                    <b-form-input class="modalclientinput" v-model="objAux.complement"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Cidade">
                                    <b-form-select class="modalclientselect" v-model="objAux.city" :options="selectcityoptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col>
                                <b-form-group label="Estado">
                                    <b-form-select class="modalclientselect" v-model="objAux.state" :options="selectStateOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="País">
                                    <b-form-select class="modalclientselect" v-model="objAux.country" :options="selectCountryOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div> -->
                    <div class="mt-4">
                        <b-button type="submit" class="savebtn rounded-pill">Salvar</b-button>
                        <b-button @click="$bvModal.hide('modal-profileclient')" class="cancelbtn rounded-pill">Cancelar</b-button>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="attendanceHistory" ref="attendanceHistory" title="Histórico de Atendimento" header-class="py-0" body-class="p-0" hide-footer v-if="userSelected">
            <div class="d-flex align-items-center p-3">
                <b-avatar class="rounded-circle attendanceHistoryAvatar bg-secondary text-white" :src="objAux.photoURL"></b-avatar>
                <div class="ms-2">
                    <div class="fw-bold">{{ objAux.name }}</div>
                    <div class="text-secondary" style="font-size: 0.85em;" v-if="objAux.company">{{ objAux.company }}</div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between text-secondary border-top border-bottom py-2 px-3">
                <div class="d-flex align-items-center">
                    <b-icon class="me-1" icon="whatsapp"></b-icon>
                    <div>{{ objAux.number }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="objAux.state">
                    <b-icon class="me-1" icon="geo-alt"></b-icon>
                    <div>{{ parseState(objAux.state) }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="objAux.attStartDate"> <!-- attendance start -->
                    <div class="text-capitalize">{{ objAux.attStartDate | date('MMM DD [●] HH:mm:ss') }}</div>
                </div>
            </div>
            <div v-if="protocols && protocols.length">
                <div class="py-2 pb-3 px-3">
                    <b-form-group label-class="text-purple" label="Protocolo:">
                        <b-form-select class="w-100 py-1 text-secondary" style="border-color: #ccc; border-radius: 0.3em" @change="pickProtocol">
                            <b-form-select-option :value="protocol" v-for="protocol in protocols" :key="protocol._id">
                                {{ protocol.protocol }} - 
                                <span class="text-capitalize">
                                    {{ (protocol.attendanceStartTime || protocol.createdAt) | date('DD/MM/YY [●] HH:mm:ss') }}
                                </span>
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="border-top" v-if="protocol">
                    <div class="bg-light rounded p-2 m-3">
                        <h5 class="mb-3 text-purple fw-normal">Status do Atendimento</h5>
                        <b-row>
                            <b-col>
                                <div class="fw-bold  text-purple">Início</div>
                                <span class="text-purple text-capitalize">{{ objAux.attStartDate | date('MMM DD [●] HH:mm:ss') }}</span>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Fim</div>
                                <span class="text-purple text-capitalize">{{ objAux.attFinishDate | date('MMM DD [●] HH:mm:ss') }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-purple">
                                <div class="fw-bold">Status</div>
                                <div class="text-purple" v-if="objAux.attStatus">
                                    {{ objAux.attStatus | translateStatus }}
                                </div>
                                <div v-else>
                                    ----
                                </div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Canal</div>
                                <div class="text-purple">{{ objAux.attChannel || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Operador</div>
                                <div class="text-purple" v-if="!objAux.operators?.length">{{ objAux.operatorName || getOperatorName(objAux.operatorId) || '----' }}</div>
                                <div class="text-purple" v-else>
                                    <span v-for="(op, i) in objAux.operators" :key="op._id">
                                        {{ op.operatorName || getOperatorName(op.operatorId) }}
                                        <span v-if="i + 1 < objAux.operators.length">
                                            <b-icon icon="arrow-right"></b-icon>
                                        </span>
                                    </span>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Telefone</div>
                                <div class="text-purple">{{ objAux.number || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Contato</div>
                                <div class="text-purple">{{ objAux.name || '----' }}</div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Gênero</div>
                                <div class="text-purple">{{ parseGender(objAux.genre) || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Estado</div>
                                <div class="text-purple">{{ objAux.state || '----' }}</div>
                            </b-col>
                            <b-col v-if="objAux.email">
                                <div class="fw-bold text-purple">Email</div>
                                <div class="text-purple">{{ objAux.email || '----' }}</div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="bg-light rounded p-2 m-3" v-if="protocol.messages && protocol.messages.length">
                        <h5 class="mb-3 text-purple fw-normal">Chat do Atendimento</h5>
                        <Whatsapp :messages="protocol.messages" :height="'15em'" :mediaURL="mediaURL" :timestampDiff="timestampDiff" :user="user" />
                        <div class="position-absolute" style="right: 2000%">
                            <div id="printHeader" class="bg-grey-2 py-4 px-5" style="width: 62em;">
                                <b-img style="width: auto; height: 3em;" :src="user.iconURL ? user.iconURL : require(`../assets/images/logomarca/logomarca-dark.png`)"></b-img>
                                <!-- <div>
                                    <div class="small-text fw-bold">Protocolo de Impressão</div>
                                    <div class="smaller-text text-secondary">
                                        {{ protocol.protocol }}
                                    </div>
                                </div> -->
                            </div>
                            <div id="printFooter" class="bg-grey-2 py-4 px-5" style="width: 62em;">
                                <b-img style="width: auto; height: 2.5em;" :src="user.iconURL ? user.iconURL : require(`../assets/images/logomarca/logomarca-dark.png`)"></b-img>
                            </div>
                        </div>
                        <div class="d-none">
                            <div class="d-flex flex-column w-100 h-100" id="element-to-convert">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between pb-4">
                                        <div class="d-flex align-items-center position-relative">
                                            <b-avatar class="rounded-circle bg-secondary text-white" size="3.5em" :src="objAux.photoURL"></b-avatar>
                                            <span class="position-absolute" style="left: 3.75em; top: 0em;">
                                                <div class="rounded-pill px-2 py-1 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(userSelected.tag)?.color}; font-size: 0.46em`" role="button" v-if="userSelected.tag && getTagInfo(userSelected.tag)">
                                                    <b-icon icon="circle-fill"></b-icon>
                                                    &#8205;
                                                    <div class="ms-1 text-uppercase">
                                                        {{ getTagInfo(objAux.tag)?.name }}
                                                    </div>
                                                </div>
                                            </span>
                                            <div class="ms-2">
                                                <div class="fw-bold">{{ objAux.name }}</div>
                                                <div class="text-secondary" style="font-size: 0.85em;" v-if="objAux.company">{{ objAux.company }}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <b-row>
                                                <b-col class="d-flex align-items-center justify-content-center fw-semibold">
                                                    <b-icon class="me-2" icon="whatsapp"></b-icon>
                                                    <div class="text-capitalize">{{ objAux.number || '----' }}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="smaller-text">
                                                <b-col class="d-flex align-items-center justify-content-center">
                                                    <b-icon class="me-2" icon="calendar-4"></b-icon>
                                                    <div class="text-capitalize">{{ objAux.attStartDate | date('MMM DD [●] HH:mm:ss') }}</div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                    <div class="w-100 border-top border-bottom py-3" style="font-size: 0.95em;">
                                        <span class="fw -bold">
                                            Protocolo: 
                                        </span>
                                        {{ protocol.protocol }}
                                    </div>
                                    <div class="py-4 border-bottom">
                                        <h5 class="mb-4 fw-bold">Status do Atendimento</h5>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Início: </span>
                                                <span class="text-capitalize">{{ objAux.attStartDate | date('MMM DD [●] HH:mm:ss') }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Fim: </span>
                                                <span class="text-capitalize">{{ objAux.attFinishDate | date('MMM DD [●] HH:mm:ss') }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Status: </span>
                                                <span class="text-capitalize" v-if="objAux.attStatus">
                                                    {{ objAux.attStatus | translateStatus }}
                                                </span>
                                                <div v-else>
                                                    ----
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Canal: </span>
                                                <span class="text-capitalize">{{ objAux.attChannel || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Contato: </span>
                                                <span class="text-capitalize">{{ objAux.name || '----' }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Telefone: </span>
                                                <span class="text-capitalize">{{ objAux.number || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Estado: </span>
                                                <span class="text-capitalize">{{ parseState(objAux.state) }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Gênero: </span>
                                                <span class="text-capitalize">{{ parseGender(objAux.genre) || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col v-if="objAux.email">
                                                <span class="fw-bold">Email: </span>
                                                <span class="text-capitalize">{{ objAux.email || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="mt-4 pb-2">
                                        <h5 class="mb-4 fw-bold">Chat do Atendimento</h5>
                                        <Whatsapp class="pdfPrint bg-secondary" :messages="protocol.messages" :height="'100%'" :mediaURL="mediaURL" :timestampDiff="timestampDiff" :pdfPrint="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center mt-3">
                            <b-button class="bg-purple border-0" @click="exportToPDF">
                                <b-spinner small v-show="generatingPDF"></b-spinner>
                                Gerar PDF
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-secondary text-center py-3" v-else>
                Nenhum atendimento encontrado!
            </div>
        </b-modal>
        <b-modal id="modal-removecontact" ref="modal-removecontact" title="Remover Contato" header-class="py-0 px-3" body-class="p-0" hide-footer v-if="userSelected">
            <div class="p-3">
                <div class="text-secondary">
                    Tem certeza de que deseja remover este contato?
                </div>
                <div class="mt-3">
                    <b-button class="savebtn" @click="deleteContact">Remover</b-button>
                    <b-button @click="$bvModal.hide('modal-removecontact')" class="cancelbtn">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-blockcontact" ref="modal-blockcontact" :title="userSelected.blocked ? 'Desbloquear Contato' : 'Bloquear Contato'" header-class="py-0 px-3" body-class="p-0" hide-footer v-if="userSelected">
            <div class="p-3">
                <div class="text-secondary">
                    {{ userSelected.blocked ? "Você realmente deseja desbloquear este contato?" : "Você realmente deseja bloquear este contato?" }}
                </div>
                <div class="mt-3">
                    <b-button class="savebtn" @click="blockContact">{{ userSelected.blocked ? "Desbloquear" : "Bloquear" }}</b-button>
                    <b-button @click="$bvModal.hide('modal-blockcontact')" class="cancelbtn">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modalForward" ref="modalForward" hide-footer title="Encaminhar Atendimento" header-class="py-0" v-if="userSelected">
            <b-form-group label="Encaminhar Para:" label-class="text-purple fw-semibold">
                <b-form-select class="w-100 rounded p-2 border-grey" v-model="forward.type" @change="forward.operator = null; forward.department = null">
                    <option>Departamento</option>
                    <option>Operador</option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-3" label="Departamento:" label-class="text-purple fw-semibold" v-if="forward.type=='Departamento'">
                <b-form-select class="w-100 rounded p-2 border-grey" v-model="forward.department">
                    <option v-for="item in departments" :key="item.id" :value="item">{{item.name}}</option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-3" label="Operador:" label-class="text-purple fw-semibold" v-else-if="forward.type=='Operador'">
                <b-form-select class="w-100 rounded p-2 border-grey" v-model="forward.operator">
                    <b-form-select-option :value="op" v-for="op in onlineOperators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3">
                <b-button class="text-white" variant="green" @click="startAttendance()">Encaminhar</b-button>
                <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalForward')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Whatsapp from './whatsapp.vue'
import api from '../services/apiService.js'
import utils from '../utils/utils'
import html2pdf from "html2pdf.js";
import domtoimage from "dom-to-image-more";
import countriesOptionsJSON from '../utils/countriesOptions.json'

export default {
    components: {
        Whatsapp
    },
    props: [
        'user', 'timestampDiff', 'mediaURL', 'socket'
    ],
    mounted: function () {
        this.onCloseModal()
        this.getContacts()
        this.getOperators()
        this.getStates()
        this.getCountrys()
        this.getAllTags()
    },
    methods: {
        async getContacts(page = 1) {
            let resp
            if(this.filter.type) {
                if(!this.filterEnabled) {
                    page = 1
                    this.currentPage = 1
                }
                resp = await api.getContactsFilter(this.user.channelId || this.user.roleId,this.filter,page)
                this.filterEnabled = true
                this.$refs['modal-filter'].hide()
            } else {
                const totalResp = await api.getContactsTotal(this.user.channelId || this.user.roleId)
                if(totalResp.statusCode != 200) {
                    this.totalContacts = 0
                }
                this.totalContacts = totalResp.numberContacts

                resp = await api.getContacts(this.user.channelId || this.user.roleId,page)
            }
            if(!resp || resp.statusCode != 200) {
                this.contacts = []
                return
            }
            this.contacts = resp.contacts
            if(this.contacts.searchContacts) {
                this.contacts = this.contacts.searchContacts
            }
            if(resp.contacts?.total != undefined)
                this.totalContacts = resp.contacts.total
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            // console.log('operators resp',resp)
            if(resp.statusCode!=200)
                return this.operators = []

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))
            
            this.operators = resp.operators
        },
        getForwardList() {
            this.getOnlineOperators()
            this.getDepartments()
        },
        async getOnlineOperators() {
            const resp = await api.getOnlineOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.onlineOperators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.onlineOperators = resp.operators
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        async startAttendance() {
            if((!this.forward.operator && !this.forward.department) || !this.userSelected) return
            const contact = this.userSelected
            if(!contact.number) contact.number = contact.clientNumber
            if(!contact.name) contact.name = contact.clientName

            let resp

            if(this.forward.operator) {
                resp = await api.retrieveAttendance(this.user.channel._id, { contact, operatorId: this.forward.operator._id })
            } else {
                resp = await api.retrieveAttendance(this.user.channel._id, { contact, departmentId: this.forward.department._id })
            }

            let msg = null

            if(resp) {
                if(resp.statusCode == 200) {
                msg = {
                    text: "Atendimento encaminhado com sucesso!",
                    success: true
                }

                this.socket.emit("channel_attendance_retrieved", resp.attendance)

                if(this.$refs['modalForward'])
                        this.$refs['modalForward'].hide()
                } else if(resp.statusCode == 406) {
                    let text

                    switch(resp.attendance.status) {
                        case 'opened':
                            text = "Este contato está com atendimento em andamento no ChatBot"
                            break
                        case 'offline_operators':
                            text = "Este contato está com atendimento em andamento na fila de espera"
                            break
                        default:
                            if (resp.attendance.operatorName)
                                text = `Este contato está com atendimento em andamento com ${resp.attendance.operatorName}!`
                            else
                                text = "Este contato está com atendimento em andamento"
                    }

                    msg = {
                        text,
                        success: false
                    }
                } else if(resp.statusCode == 408) {
                    msg = {
                        text: "O horário de atendimento deste operador foi finalizado!",
                        success: false,
                        countdown: 10
                    }
                } else if(resp.statusCode === 409) {
                    msg = {
                        text: "Este contato está em pesquisa de satisfação!",
                        success: false
                    }
                } else if(resp.statusCode == 404) {
                    msg = {
                        text: "Nenhum operador disponível",
                        success: false,
                        countdown: 10
                    }
                } else {
                    msg = {
                        text: "Ocorreu um erro!",
                        success: false
                    }
                }
            } else {
                msg = {
                    text: "Ocorreu um erro!",
                    success: false
                }
            }

            if(msg) {
                this.$emit('msg', msg)
            }
        },
        selectContact(item) {
            if(item.birthDate) {
                item.birthDate = this.$options.filters.date(item.birthDate,'YYYY-MM-DD')
                // console.log(item.birthDate)
            }
            this.userSelected = item;
            this.objAux = Object.assign({}, item);
        },
        async saveContact() {
            let isNew = false
            let resp
            let msg
            if(this.objAux?.birthDate?.length<11) {
                this.objAux.birthDate = `${this.objAux.birthDate}T04:00:00.000Z`
            }
            if(this.objAux.document) {
                this.objAux.document = this.objAux.document.replace(/\D+/g, '')
            }
            if(this.objAux.number) {
                this.objAux.number = this.objAux.number.replace(/\D+/g, '')
            }
            if(!this.objAux._id) {
                isNew = true
            }
            if(!this.objAux.responsibleOperatorId)
                this.objAux.responsibleOperatorId = "null"
            if(isNew) {
                this.objAux.channelId = this.user.channelId || this.user.roleId
                resp = await api.createContact(this.objAux)
            } else {
                resp = await api.updateContact(this.objAux)
            }
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                msg = {
                    text: `Contato ${isNew ? 'criado' : 'editado'} com sucesso`,
                    success: true
                }
                this.$refs['modal-profileclient'].hide()
            } else {
                msg = {
                    text: `Ocorreu um erro!`,
                    success: false
                }
            }
            this.$emit('msg', msg) 
            this.getContacts(this.currentPage)
        },
        async deleteContact() {
            const resp = await api.deleteContact(this.objAux._id)
            let msg
            if(resp.error || resp.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro!',
                    success: false
                }
            } else {
                msg = {
                    text: "Contato removido com sucesso!",
                    success: true
                }
            }
            this.$refs['modal-removecontact'].hide()
            this.$emit('msg', msg)
            this.getContacts()
        },
        async blockContact() {
            let resp, msg
            const blocking = !this.userSelected.blocked
            if(blocking) {
                resp = await api.blockContact(this.userSelected)
            } else {
                resp = await api.unblockContact(this.userSelected)
            }

            if(resp.error || resp.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro!',
                    success: false
                }
            } else {
                msg = {
                    text: `Contato ${blocking ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
                    success: true
                }
                this.userSelected.blocked = blocking
            }
            this.$refs['modal-blockcontact'].hide()

            if(msg)
                this.$emit('msg', msg)
        },
        async getAllContacts() {
            const resp = await api.getAllContacts(this.user.channelId || this.user.roleId, this.filter)
            // console.log('resp',resp)
            if(resp.statusCode == 200) {
                return resp.contacts
            } else {
                return null
            }
        },
        async exportCSV() {
            this.$emit('msg', {
                text:'Os contatos estão sendo carregados, por favor aguarde!',
                success: true,
            }) 

            const contacts = await this.getAllContacts()
            if(contacts) {
                if(!contacts.length) {
                    return this.$emit('msg', {
                        text:'Nenhum contato encontrado!',
                        success: false,
                    }) 
                }
                const model = contacts.map(d=>{
                    return {
                        Nome: d.name,
                        Whatsapp: d.number,
                        Estado: d.state,
                        Email: d.email,
                        Genero: this.parseGender(d.genre),
                        DataNascimento: this.$options.filters.date(d.birthDate,'YYYY-MM-DD')
                    }
                })
                const csv = utils.convertToCSV(model)
                utils.downloadCSV(csv)
            } else {
                this.$emit('msg', {
                    text:'Ocorreu um erro ao exportar contatos!',
                    success: false,
                }) 
            }
        },
        async loadProtocols(contact) {
            // console.log('contact',contact)
            const resp = await api.getContactHistory(this.user.channelId || this.user.roleId, contact.number)
            // console.log('resp protocols',resp)
            if(resp.statusCode == 200) {
                this.protocols = resp.result.attendances.sort((a,b) => (a.attendanceStartTime > b.attendanceStartTime) ? 1 : ((b.attendanceStartTime > a.attendanceStartTime) ? -1 : 0))
            } else {
                this.protocols = []
            }
        },
        async pickProtocol(protocol) {
            this.protocol = protocol
            this.objAux.attFinishDate = protocol.attendanceEndTime
            this.objAux.attStatus = protocol.status
            this.objAux.attChannel = protocol.channelNumber
            this.objAux.operatorId = protocol.operatorId
            this.objAux.operatorName = protocol.operatorName
            this.objAux.operators = protocol.operators
            this.$set(this.objAux,'attStartDate',protocol.attendanceStartTime)
            const resp = await api.getProtocolMessages(protocol._id)
            // console.log('resp protocol messages',resp)
            this.$set(this.protocol,'messages',resp.messages?.messages)
        },
        async getStates() {
            this.selectStateOptions = (await api.getStates()).map(el => ({
                value: el.sigla,
                text: el.nome
            }))
        },
        async getCountrys() {
            const seen = new Set()
            this.selectCountryOptions = countriesOptionsJSON
                .filter(el => !seen.has(el.isoCode) && seen.add(el.isoCode))
                .map(el => ({
                    value: el.code,
                    text: utils.getCountryName(el.code)
                }))
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getContacts(page)
            }
        },
        disableFilter() {
            this.filter.type = null
            this.filterEnabled = false
            this.currentPage = 1
            this.getContacts()
        },
        parseState(state) {
            return utils.siglaPraEstadoCompleto(state) || 'N/D'
        },
        parseCountry(countryCode) {
            return utils.getCountryName(countryCode) || 'N/D'
        },
        parseGender(gender) {
            const genderMap = {
                'M': 'Masculino',
                'F': 'Feminino',
            }
            return genderMap[gender] || 'Não Identificado'
        },
        getOperatorName(operatorId) {
            return this.operators.find(el => el._id === operatorId)?.name || null
        },
        getTagInfo(tagId) {
            return this.tags.find(el => el._id === tagId)
        },
        async getAllTags() {
            const resp = await api.getAllTags(this.user.channelId || this.user.roleId)
            // console.log('resp tags',resp)
            if(!resp || resp.statusCode != 200) {
                this.selectTagOptions = [{ value: null, text: "Nenhum" }]
                this.tags = []
            } else {
                const options = [{ value: null, text: "Nenhum" }]
                resp.tags.map(el => {
                    options.push({
                        value: el._id,
                        text: el.name
                    })
                })
                this.selectTagOptions = options
                this.tags = resp.tags
            }
        },
        toggleTagName(item) {
            if(!item.showTagName)
                this.$set(item,"showTagName",true)
            else
                this.$set(item,"showTagName",false)
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.userSelected = null
                this.objAux = {}
                this.protocol = null
            })
        },
        async exportToPDF() {
            if(!this.generatingPDF) {
                this.generatingPDF = true
                await html2pdf().from(document.getElementById("element-to-convert")).set({
                    margin: [ 28, 10 ],
                    filename: `${this.protocol.protocol}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    enableLinks: true,
                    html2canvas: {
                        dpi: 192,
                        scale: 2,
                        letterRendering: true,
                        useCORS: true
                    },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                }).toPdf().get('pdf').then(async (pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages();
                    const printHeader = await domtoimage.toPng(document.getElementById("printHeader"))
                    const printFooter = await domtoimage.toPng(document.getElementById("printFooter"))
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        const header = new Image();
                        header.src = printHeader
                        pdf.addImage(header, 'png', 0, 0, pdf.internal.pageSize.getWidth(), 20)
    
                        pdf.setFontSize(12);
                        pdf.setTextColor(0);
                        pdf.text('Protocolo de Impressão', pdf.internal.pageSize.getWidth() * 0.74, 9)
    
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text(this.protocol.protocol, pdf.internal.pageSize.getWidth() * 0.74, 13)
    
                        const footer = new Image();
                        footer.src = printFooter
                        pdf.addImage(footer, 'png', 0, pdf.internal.pageSize.getHeight() - 20, pdf.internal.pageSize.getWidth(), 20)
                        
                        pdf.setFontSize(10);
                        pdf.setTextColor(50);
                        pdf.text('Pág: ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.85), (pdf.internal.pageSize.getHeight() - 9))
                    }
                }).save().catch(err => {
                    if(err) {
                        console.log('err',err)
                        this.$emit("msg", { 
                            text: "Ocorreu um erro ao gerar o PDF!",
                            success: false
                        })
                    }
                });
                this.generatingPDF = false
            }
        },
    },
    data() {
        return {
            objAux:{},
            userSelected: null,
            filterselected1: null,
            filterselected2: null,
            filterselectedinitial: null,
            filterselectedfinal: null,
            currentPage: 1,
            perPage: 9,
            modalfilterselectoptions: [
                { value: null, text: 'Selecione', disabled:true},
                { value: 'a', text: 'Todos' },
                { value: 'b', text: 'Região' },
                { value: 'c', text: 'Gênero' },
                { value: 'd', text: 'Whatsapp'},
                { value: 'e', text: 'Bloqueados'},
                { value: 'f', text: 'Pop-ups'},
                { value: 'g', text: 'Importados'},
            ],
            modalfilterselectregionoptions: [
                { value: null, text: 'Selecione', disabled:true},
                { value: 'a', text: 'Região Norte' },
                { value: 'b', text: 'Região Nordeste' },
                { value: 'c', text: 'Região Centro-Oeste' },
                { value: 'd', text: 'Região Sudeste'},
                { value: 'e', text: 'Região Sul'},
            ],
            modalfilterselectgenderoptions: [
                { value: null, text: 'Selecione', disabled: true},
                { value: 'F', text: 'Feminino' },
                { value: 'M', text: 'Masculino' },
                { value: null, text: 'Outros' },
            ],
            modalfilterselectwhatsappoptions: [
                { value: null, text: 'Selecione', disabled: true},
                { value: 'a', text: 'Comercial' },
                { value: 'b', text: 'Pessoal' },
            ],
            modalinitialselect: [
                { value: null, text: 'Data Inicial' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Default Selected Option' },
                { value: 'c', text: 'This is another option' },
                { value: 'd', text: 'This one is disabled'}
            ],
            modalfinalselect: [
                { value: null, text: 'Data Final' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Default Selected Option' },
                { value: 'c', text: 'This is another option' },
                { value: 'd', text: 'This one is disabled'}
            ],
            selected: null,
            selectnacionalityoptions: [
                { value: null, text: 'Nacionalidade', disabled: true },
            ],
            selectgenderoptions: [
                { value: null, text: 'Gênero', disabled: true},
                { value: 'F', text: 'Feminino'},
                { value: 'M', text: 'Masculino'},
            ],
            selectcityoptions: [
                { value: null, text: 'Sua Cidade', disabled: true},
            ],
            selectStateOptions: [],
            selectCountryOptions: [],
            selectTagOptions: [
                { value: null, text: "Nenhum" }
            ],
            totalContacts: 0,
            contacts: [],
            operators: [],
            onlineOperators: [],
            departments: [],
            filter: { 
                invalid: false
            },
            filterEnabled: false,
            forward: {},
            protocols: [],
            protocol: null,
            tags: [],
            generatingPDF: false,
        }
    }
}
</script>

<style>
    .tooltip-clientaccess>.tooltip-inner {
        background-color: hsl(107deg 62% 78%);
        color: hsl(242deg 22% 45%);
    }
    .tooltip-clientoperator>.tooltip-inner {
        background-color: hsl(52deg 100% 50%);
        color: hsl(242deg 22% 45%);
    }
    #modal-filter .close,#modal-removecontact .close,#modal-export .close, #attendanceHistory .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: #777;
    }
    #modal-filter .modal-header,#modal-removecontact .modal-header,#modal-export .modal-header{
        color: #777;
        padding: 10px 0;
    }
    #modal-filter .modal-body:first-child,#modal-removecontact .modal-body:first-child,#modal-export .modal-body:first-child{
        padding: 0;
    }
    #modal-filter .modal-body,#modal-removecontact .modal-body,#modal-export .modal-body{
        padding: 10px 0;
        padding-bottom: 25px;
    }
    .modal-filterselect option[value=""][disabled] {
        display: none;
    }
    #modal-filter .modal-footer{
        display: none;
    }
    .modalclientdialog{
        max-width: 550px;
    }
    .modalclientdialogsm{
        max-width: 320px;
    }
    .registerclientheader{
        background-color: hsl(250deg 38% 32%);
        padding: 2em;
    }
    .profileclientheader{
        background-color: hsl(241deg 33% 60%);
    }
    option:disabled {
        display: none;
    }
    .modalclientdatepicker .text-muted{
        color: hsl(250deg 36% 74%) !important;
    }
    .modalclientheader{
        color: hsl(240deg 29% 19%);
    }
    .modalclientheader .modal-title{
        font-size: 1em;
    }
    .modalclientheader .close{
        border: none;
        background-color: transparent;
        color: hsl(250deg 38% 76%);
        font-size: 2em;
        line-height: 0;
        padding: 0;
    }
    .contactspagination .page-link{
        color: #888;
        border: none;
        border-radius: 50%;
    }
    .contactspagination .page-link:hover{
        color: hsl(243deg 16% 51%);
    }
    .contactspagination .page-item.active .page-link{
        background-color: hsl(142deg 38% 59%);
        color: #fff;
    }
    .card{
        border: none;
    }
    .checkboxdelete .b-icon{
        color: hsl(242deg 22% 45%);
    }
    .checkboxdelete label{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .checkboxdelete label.active{
        background-color: hsl(0deg 87% 67%);
        border-color: hsl(0deg 87% 67%);
    }
    .checkboxdelete label input{
        display: none;
    }
</style>

<style scoped>
    .contacts-cardtop{
        padding: 20px;
    }
    .contacts-cardtoptextdiv{
        float: left;
    }
    .contacts-cardtopbtndiv{
        float: right;
    }
    .contacts-cardtitle{
        font-weight: 400;
    }
    .contacts-total{
        color: #999;
    }
    .contacts-totalnumber{
        background-color: hsl(242deg 22% 45%);
        color: #fff;
        padding: 2px 15px;
        border-radius: 25px;
        font-weight: 500;
    }
    .contacts-cardtopbtn{
        color: #fff;
        padding: 8px 30px;
        border:none;
        margin-right: 5px;
        margin-top: 10px;
    }
    .contacts-cardtopbtn:last-child{
        margin-right: 0;
    }
    .contacts-cardtopbtnfilter{
        background-color: hsl(87deg 47% 59%);
    }
    .contacts-cardtopbtnremove{
        background-color: hsl(0deg 87% 67%);
    }
    .contacts-cardtopbtnexport{
        background-color: hsl(243deg 39% 65%);
    }
    .modal-bodytitle{
        color: #999;
        font-weight: 500;
        margin: 5px 0;
    }
    .modal-filterselect, .modal-filterinput{
        border: 1px solid #eee;
        box-shadow: none;
        outline: none;
    }
    .modal-filterselect{
        display: block;
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        color: #888;
    }
    .modal-filterselect:disabled{
        color: #ddd;
    }
    .modal-filterinput, .modal-filterinput::placeholder{
        color: #888;
        padding: 5px;
        border-radius: 5px;
    }
    .modal-filterinput::placeholder{
        padding: 4px;
    }
    .modal-filterselect:nth-child(2) {
        margin-bottom: 10px;
    }
    .modal-datarow{
        margin: 0px;
    }
    .modal-datacol{
        padding: 0;
    }
    .modal-datacol label{
        color: #999;
    }
    .modal-datepicker{
        border: 1px solid #eee;
    }
    .modal-datacol:first-child{
        margin-right: 5px;
    }
    .modal-datacol:last-child{
        margin-left: 5px;
    }
    .modal-btn{
        color: #999;
        background-color: #fff;
        border: 0.5px solid #eee;
        padding: 5px 30px;
        margin-top: 10px;
        margin-right: 10px;
        border-radius: 5px;
        font-weight: 500;
    }
    .modal-btnfilter{
        background-color: hsl(142deg 38% 59%);
        color: #fff;
        border: none;
    }
    .contactcard{
        border: none;
        padding: 0;
        /* margin-bottom: 20px; */
        border-radius: 5px;
    }
    .contactcard .card-body{
        padding: 0;
    }
    .row1{
        color: #fff;
        padding: 0 12px;
        text-align: center;
    }
    .row1div{
        background-color: hsl(242deg 22% 45%);
        padding: 25px 0;
        padding-bottom: 60px;
        border-radius: 5px 5px 0 0;
    }
    .threedots{
        height: 48px;
        width: 48px;
        border-radius: 50%;
        cursor: pointer;
    }
    .threedotsdrop{
        position: relative;
        display: none;
    }
    .threedotsdroptriangle{
        height: 15px;
        width: 15px;
        position: absolute;
        background-color: #fff;
        top: -8px;
        transform: rotate(45deg);
        display: none;
    }
    .threedotsdropcontent{
        position: absolute;
        background-color: #fff;
        padding: 15px 0;
        text-align: left;
        border-radius: 5px;
        top: -43px;
        left: -20px;
        cursor:default;
        font-size: 0.8em;
        z-index: 1;
    }
    .threedotsdropbtns{
        padding: 5px 20px;
        white-space: nowrap;
        cursor: pointer;
    }
    .threedotsdropbtns:hover{
        background-color: #eee;
    }
    .threedotsicon{
        font-size: 1.8em;
    }
    .threedots:hover .threedotsdrop{
        display: block;
    }
    .taghover{
        position: absolute;
        right: 25px;
    }
    .taghovericon{
        color: hsl(242deg 22% 45%);
        background-color: #fff;
        border-radius: 50%;
        font-size: 3.1em;
        padding: 8px;
        padding-left: 13px;
    }
    .contactcardcol{
        padding: 0;
        margin-bottom: 20px;
    }
    .contactcardcol:not(:nth-child(3n)) {
        padding-right: 1em;
    }
    .contacticon{
        border: 6px solid #fff;
        border-radius: 50%;
        height: 90px;
        width: 90px;
        margin-top: 40px;
    }
    .clientavatarprofile{
        height: 120px;
        width: 120px;
        border: 8px solid #fff;
        margin: 0;
    }
    .contactname{
        margin-top: 4px;
        font-size: 1.2em;
    }
    .contactspagination{
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-radius: 4px;
    }
    .pagdescription{
        color: #555;
    }
    .registerclientnameinput{
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #fff;
        text-align: center;
        font-size: 1.25em;
        color: #fff;
        outline: none !important;
        box-shadow: none !important;
    }
    .registerclientnameinput::placeholder{
        color: #aaa;
    }
    .modalclientname{
        color: #fff;
        font-size: 1.4em;
    }
    .modalclosebtn{
        position: absolute;
        top: -45px;
        right: -12px;
    }
    .formwrapper{
        color: hsl(250deg 38% 31%);
    }
    .formwrapperprofile{
        color: hsl(250deg 36% 74%);
    }
    .formtitle{
        font-size: 1.25em;
        border-top: 1px dashed hsl(249deg 39% 74%);
        border-bottom: 1px dashed hsl(249deg 39% 74%);
        padding: 15px 0;
    }
    .attendanceHistoryAvatar{
        width: 4em;
        height: 4em;
    }
    .modalclientselect, .modalclientinput{
        border: 1px solid hsl(250deg 36% 74%);
        padding: 6px 20px;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 3px;
    }
    .modalclientinputsm{
        font-size: 0.9em;
        border: 1px solid #eee;
        padding: 5px 15px;
    }
    .modalclientselect{
        width: 100%;
    }
    .modalclientselect, .modalclientinput::placeholder{
        color: hsl(250deg 36% 74%);
    }
    .modalclientdatepicker{
        border: 1px solid hsl(250deg 36% 74%);
        outline: none !important;
        box-shadow: none !important;
        border-radius: 3px;
    }
    .modalclientappend{
        background-color: hsl(250deg 36% 74%);
        padding: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid hsl(250deg 36% 74%);
        cursor: pointer;
    }

    .tagSpanHover {
        display: none;
    }
    .tagSpan:hover .tagSpanHover {
        display: block;
    }

    @-moz-document url-prefix() {
        .modalclientselect, .modalclientinput{
            padding: 7.5px 20px !important;
        }
        .modalclientappend{
            padding: 9.5px !important;
        }
    }
    @media(max-width: 425px) {
        .contacts-cardtop{
            text-align: center;
        }
        .contacts-cardtoptextdiv{
            float: none;
        }
        .contactcardcol{
            width: 90%;
        }
    }
</style>